/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MemberDataItem } from './MemberDataItem';

/**
 * An optional object, providing the actual result.
 */
export type EligibilityMatchesResponse = {
    referenceDate: MemberDataItem;
    /**
     * A list of potential matches for the reference user
     */
    potentialMatches: Array<MemberDataItem>;
    /**
     * Last eligibility status
     */
    eligibilityStatus: EligibilityMatchesResponse.eligibilityStatus;
};

export namespace EligibilityMatchesResponse {

    /**
     * Last eligibility status
     */
    export enum eligibilityStatus {
        ELIGIBLE = 'ELIGIBLE',
        INELIGIBLE = 'INELIGIBLE',
        INFO_UNAVAILABLE = 'INFO_UNAVAILABLE',
        SKIPPED = 'SKIPPED',
        EXTERNAL_PROVIDER_ERROR = 'EXTERNAL_PROVIDER_ERROR',
    }


}

