/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessGetSuggestedTreatmentPlanResponse } from '../models/SuccessGetSuggestedTreatmentPlanResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TreatmentPlanControllerService {

    /**
     * Allows to retrieve treatment plan suggestion or next step required to reach treatment plan for user.
     * @param treatmentTarget The target of the suggested treatment plan. Currently only 'cashpay' is supported.
     * @returns SuccessGetSuggestedTreatmentPlanResponse Treatment plan suggestion has been fetched successfully
     * @throws ApiError
     */
    public static suggestTreatmentPlanForTarget(
        treatmentTarget: string,
    ): CancelablePromise<SuccessGetSuggestedTreatmentPlanResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/treatmentplan/{treatmentTarget}/suggestion',
            path: {
                'treatmentTarget': treatmentTarget,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Allows to retrieve treatment plan suggestion or next step required to reach treatment plan for user.
     * @returns SuccessGetSuggestedTreatmentPlanResponse Treatment plan suggestion has been fetched successfully
     * @throws ApiError
     */
    public static suggestTreatmentPlan(): CancelablePromise<SuccessGetSuggestedTreatmentPlanResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/treatmentplan/suggestion',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
