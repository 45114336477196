/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A list of potential matches for the reference user
 */
export type MemberDataItem = {
    /**
     * Member's first name
     */
    firstName: string;
    /**
     * Member's last name
     */
    lastName: string;
    /**
     * Member's Date of birth
     */
    dob: string;
    /**
     * Indicates whether the user is a subscriber or a dependent
     */
    isSubscriber: boolean;
    /**
     * The city where the member lives according to their payer
     */
    city?: string;
    /**
     * The state where the member lives according to their payer
     */
    state?: MemberDataItem.state;
    /**
     * The zip code where the member lives according to their payer
     */
    zip?: string;
};

export namespace MemberDataItem {

    /**
     * The state where the member lives according to their payer
     */
    export enum state {
        AA = 'AA',
        AE = 'AE',
        AL = 'AL',
        AK = 'AK',
        AP = 'AP',
        AS = 'AS',
        AZ = 'AZ',
        AR = 'AR',
        CA = 'CA',
        CO = 'CO',
        CT = 'CT',
        DE = 'DE',
        DC = 'DC',
        FM = 'FM',
        FL = 'FL',
        GA = 'GA',
        GU = 'GU',
        HI = 'HI',
        ID = 'ID',
        IL = 'IL',
        IN = 'IN',
        IA = 'IA',
        KS = 'KS',
        KY = 'KY',
        LA = 'LA',
        ME = 'ME',
        MD = 'MD',
        MH = 'MH',
        MA = 'MA',
        MI = 'MI',
        MN = 'MN',
        MS = 'MS',
        MO = 'MO',
        MT = 'MT',
        MP = 'MP',
        NE = 'NE',
        NV = 'NV',
        NH = 'NH',
        NJ = 'NJ',
        NM = 'NM',
        NY = 'NY',
        NC = 'NC',
        ND = 'ND',
        OH = 'OH',
        OK = 'OK',
        OR = 'OR',
        PW = 'PW',
        PA = 'PA',
        PR = 'PR',
        RI = 'RI',
        SC = 'SC',
        SD = 'SD',
        TN = 'TN',
        TX = 'TX',
        UT = 'UT',
        VT = 'VT',
        VI = 'VI',
        VA = 'VA',
        WA = 'WA',
        WV = 'WV',
        WI = 'WI',
        WY = 'WY',
    }


}

